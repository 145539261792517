/**
 * USAGE:
 * import this JS and the css file
 * add a slideshow element to your document
 * customize it with the folowing parameter:
 *  * hideDots: Set this to 1 if you don't want to show the navigation dots at the bottom
 *  * slideTime: This is the time in Millisecond each image is shown
 *  * imgLinks: This is for the images to show, as divider between images use ";".
 */


 let slideshowIndex = -1; // in this variable we save the current image (we set this default to -1, because before showing we add 1, so we start with image 0)
 let slideshowElement = document.getElementsByTagName("slideshow")[0]; // get the slideshow element from DOM
 
 let images = Array.prototype.slice.call(slideshowElement.getElementsByTagName("img")).map(function(element) {
     return {
         "src": element.src,
         "caption": element.getAttribute("caption") || null,
         "link": element.getAttribute("href") || "#",
         "animation": element.getAttribute("animation") || null,
         "direction": element.getAttribute("direction") || null,
         "animationTime": element.getAttribute("animationTime") || null,
     }
 }); // get the image and parse all argumentsit
 
 let slideTime = slideshowElement.getAttribute("slideTime") || 5000; // get the time argument
 let newSlide = setInterval(slideshow, slideTime); //start the timer
 let useImage1 = true; //we use 2 img elements for animations
 let pause = false;
 let animationID = 0; //used for rotateAnimation parameter
 
 //setup the basic HTML structure
 slideshowElement.innerHTML = '\
 <div class="slideshow-container">\
     <a id="slideshow-link" href=#>\
     <img id="slideshow-img1">\
     <img id="slideshow-img2">\
     <p id="slideshow-caption"></p>\
     <a id="slideshow-pause" onClick="pauseHandler()"><div class="pauseIcon"></div></a>\
 </div>\
 <div id="slideshow-dots">\
 </div>\
 ';
 
 if (!slideshowElement.hasAttribute("hideDots")) { //check if dots are disabled
     for (let i = 0; i < images.length; i++) { //add for every image a dot in the slideshow-dots div
         document.getElementById("slideshow-dots").innerHTML = document.getElementById("slideshow-dots").innerHTML + '\n <i class="dot" onclick="jumpToSlide(' + i + ')"></i>  ' //add the dot and set the onclick function to jump to this image
     }
 }
 if (!slideshowElement.hasAttribute("pauseButton")) { //check if pause button is enabled
     document.getElementById("slideshow-pause").style.display = "none"
 }
 if (slideshowElement.hasAttribute("linkNewTab")) {
     document.getElementById("slideshow-link").setAttribute("target", "_blank");
 }
 
 
 /**
  * This function sets the image parsed by the index parameter and also handles the dot's
  * @param {number} index This index in imageLinks of the image we wan't to show
  */
 function setSlide(index) {
     let slideDots = document.getElementById("slideshow-dots").getElementsByTagName("i"); // get the dots
     for (let i = 0; i < slideDots.length; i++) { //run this for all dots
         if (index == i) { //check if the current dot matches our index
             slideDots[i].className = "dot-filled"; //if yes, the dot is filles
         } else {
             slideDots[i].className = "dot-empty"; //if no, we only show a circle
         }
     };
     let animation = images[index].animation || slideshowElement.getAttribute("animation") || "fade";
     let direction = images[index].direction || "left";
 
     let animationTime = images[index].animationTime || slideshowElement.getAttribute("animationTime") || 1000;
     if (slideshowElement.hasAttribute("rotateAnimation")) {
         animationID++;
         if (animationID > 5) {
             animationID = 0;
         }
     }
     if (slideshowElement.hasAttribute("randomAnimation")) {
         animationID = Math.floor(Math.random() * 6);
     }
 
     if (slideshowElement.hasAttribute("rotateAnimation") || slideshowElement.hasAttribute("randomAnimation")) {
         switch (animationID) {
             case 1:
                 animation = "fade";
                 break;
             case 2:
                 animation = "rotate";
                 break;
             case 3:
                 animation = "push";
                 direction = "up";
                 break;
             case 4:
                 animation = "push";
                 direction = "down";
                 break;
             case 5:
                 animation = "push";
                 direction = "left";
                 break;
             case 6:
                 animation = "push";
                 direction = "right";
                 break;
             default:
                 animationID = 0;
                 animation = "fade";
                 break;
         }
     }
 
     let img1 = "slideshow-img1";
     let img2 = "slideshow-img2";
     if (!useImage1) {
         img1 = "slideshow-img2";
         img2 = "slideshow-img1";
     }
 
     document.getElementById(img1).src = images[index].src; //set the link of the image
     switch (animation) {
         case "fade":
             fadeImg(document.getElementById(img2), document.getElementById(img1), animationTime);
             break;
         case "rotate":
             rotate(document.getElementById(img2), document.getElementById(img1), animationTime);
             break;
         case "push":
             push(document.getElementById(img2), document.getElementById(img1), direction, animationTime);
             break;
     }
 
     useImage1 = !useImage1; //set useImage1 to the oposite (true ==> false and false ==> true)
 
     if (images[index].caption != null) { //if we have captions, set it
         document.getElementById("slideshow-caption").innerHTML = images[index].caption; //get the caption element and set the caption of the current image
         document.getElementById("slideshow-caption").style.opacity = 1;
     } else {
         document.getElementById("slideshow-caption").style.opacity = 0;
     }
 
     document.getElementById("slideshow-link").setAttribute("href", images[index].link);
 
 
 }
 /**
  * this function will be executed by the slide timer
  */
 function slideshow() {
 
     if (!document.hidden) {
         slideshowIndex++; //use next image
         if (slideshowIndex >= images.length) { //check if our counter is higher than the last link
             slideshowIndex = 0; //if yes, reset it to 0
         }
         setSlide(slideshowIndex); //show the new image
     }
 }
 
 /**
  * This function is called if you click on a dot below the slider
  * @param {number} index This is the index we want to show next
  */
 function jumpToSlide(index) {
     clearInterval(newSlide); //cancel the main task (to reset the timer)
     slideshowIndex = index; // set slideshowIndex to our new index
     setSlide(index); //show the new image
     if (!pause) newSlide = setInterval(slideshow, slideTime); //restart the timer
 }
 

 /**
  * This function is called by the pause / play button, to cancel or start the timer.
  */
 function pauseHandler() {
     if (pause) {
         newSlide = setInterval(slideshow, slideTime); //start the timer
         document.getElementById("slideshow-pause").getElementsByTagName("div")[0].className = "pauseIcon";
     } else {
         clearInterval(newSlide); //stop the timer
         document.getElementById("slideshow-pause").getElementsByTagName("div")[0].className = "playIcon";
     }
     pause = !pause;
 
 }
 
 /*
                      _                       _     _                       
      /\             (_)                     | |   (_)                      
     /  \     _ __    _   _ __ ___     __ _  | |_   _    ___    _ __    ___ 
    / /\ \   | '_ \  | | | '_ ` _ \   / _` | | __| | |  / _ \  | '_ \  / __|
   / ____ \  | | | | | | | | | | | | | (_| | | |_  | | | (_) | | | | | \__ \
  /_/    \_\ |_| |_| |_| |_| |_| |_|  \__,_|  \__| |_|  \___/  |_| |_| |___/
  */
 
 /**
  * This function fades image 1 to image 2.
  * @param {object} img1 Image 1 element
  * @param {object} img2 Image 2 element
  * @param {number} time Time in ms the animation lasts
  * @param {number} i Value between 0 and 100, which equals the process of the animation (needed for IE support, don't needed to set)
  */
 function fadeImg(img1, img2, time, i) {
     if (i === undefined) i = 0;
     img1.style.opacity = (100 - i) / 100;
     img2.style.opacity = i / 100;
     if (i >= 100) {
         return;
     }
     setTimeout(function() { fadeImg(img1, img2, time, i + 1) }, time / 100);
 }
 
 /**
  * This function rotates and at the same time decreases image 1, until it's size is 0 and then does the opposite for image 2
  * @param {object} img1 Image 1 element
  * @param {object} img2 Image 2 element
  * @param {number} time Time in ms the animation lasts
  * @param {number} i Value between 0 and 200, which equals the process of the animation (needed for IE support, don't needed to set)
  */
 function rotate(img1, img2, time, i) {
     if (i === undefined) i = 0;
     if (i <= 100) {
         img1.style.transform = "rotate(" + i * 7 + "deg) scale(" + (100 - i) / 100 + ")";
     }
     if (i == 100) {
         img2.style.transform = "scale(0)";
         img2.style.opacity = 1;
 
         img1.style.opacity = 0;
         img1.style.transform = "";
     }
 
     if (i > 100 && i <= 200) {
         img2.style.transform = "rotate(" + (((i - 100) * 7) + 20) + "deg) scale(" + (i - 100) / 100 + ")";
     }
     if (i > 200) {
         return;
     }
     setTimeout(function() { rotate(img1, img2, time, i + 1) }, time / 200);
 
 }
 
 /**
  * This function pushes a new image from right to left and also pushes the current image out.
  * @param {object} img1 Image 1 element
  * @param {object} img2 Image 2 element
  * @param {number} time Time in ms the animation lasts
  * @param {number} i Value between 0 and 100, which equals the process of the animation (needed for IE support, don't needed to set)
  */
 function push(img1, img2, direction, time, i) {
     if (i === undefined) {
         i = 0;
         switch (direction) {
             case "left":
                 img2.style.transform = "translate(100%, 0)";
                 break;
             case "right":
                 img2.style.transform = "translate(-100%, 0)";
                 break;
             case "up":
                 img2.style.transform = "translate(0, 100%)";
                 break;
             case "down":
                 img2.style.transform = "translate(0, -100%)";
                 break;
         }
         img2.style.opacity = 1;
     };
 
     switch (direction) {
         case "left":
             img1.style.transform = "translate(-" + i + "%, 0)";
             img2.style.transform = "translate(" + (100 - i) + "%, 0)";
             break;
         case "right":
             img1.style.transform = "translate(" + i + "%, 0)";
             img2.style.transform = "translate(-" + (100 - i) + "%, 0)";
             break;
         case "up":
             img1.style.transform = "translate(0, -" + i + "%)";
             img2.style.transform = "translate(0, " + (100 - i) + "%)";
             break;
         case "down":
             img1.style.transform = "translate(0, " + i + "%)";
             img2.style.transform = "translate(0, -" + (100 - i) + "%)";
             break;
     }
 
 
 
     if (i >= 100) {
         img1.style.opacity = 0;
         img1.style.transform = "";
         return;
     }
     setTimeout(function() { push(img1, img2, direction, time, i + 1) }, time / 200);
 
 }
 
 slideshow(); //call the background task once, to show the first image before the timer first executes
 